import React from "react";
import PropTypes from "prop-types";
import "./Header.scss";
import { sendTagOnContactCall } from "app/utils/analytics";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconPhone from "app/pages/.shared/static/icons/IconPhoneBordered";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { isProductPage } from "app/utils/urlDataExtractor";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";

const Header = ({
	displayPhone,
	callPrice,
	dialPhone,
	match,
	fallbackRedirectUrl,
	useBrowserBackOnProductPage,
}) => {
	const isFicheProduitPage = isProductPage(match.path);

	return (
		<div className="header">
			<div className="header__main">
				{isFicheProduitPage && (
					<div className="header__back">
						<BackButton
							forceBrowserBack={useBrowserBackOnProductPage}
							to={{
								pathname: fallbackRedirectUrl,
							}}
							label=""
						/>
					</div>
				)}

				<div className="header__logo" style={{ color: "black" }}>
					<RelativeLink
						to={{
							pathname: fallbackRedirectUrl,
						}}
					>
						<LogoBrandContainer />
					</RelativeLink>
				</div>

				<a
					className="header__phone"
					onClick={sendTagOnContactCall}
					href={`tel:${dialPhone}`}
				>
					<IconPhone height={30} width={30} />
					<div className="header__phone-callcenter">
						<div className="header__phone__display-phone">{displayPhone.join(" ")}</div>
						<div className="header__phone__price">{callPrice}</div>
					</div>
				</a>
			</div>
		</div>
	);
};

Header.defaultProps = {
	displayPhone: [],
	callPrice: "",
	dialPhone: "",
};

Header.propTypes = {
	displayPhone: PropTypes.array,
	callPrice: PropTypes.string,
	dialPhone: PropTypes.string,
	match: PropTypes.object,
	fallbackRedirectUrl: PropTypes.string,
	useBrowserBackOnProductPage: PropTypes.bool,
};

export default React.memo(Header);
